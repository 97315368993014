import React, { Component } from 'react';
import { Redirect } from 'react-router';

// Import custom components
import ErrorHandler from './common/errorHandler';
import Header from './common/header/header.component';
import Sidebar from './common/sidebar/sidebar.component';
//import Footer from './common/footer/footer.component';
//import './api-config';
import '../assets/css/encss.css';
import axios from 'axios';

import { Provider } from 'react-redux';
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import { Router, browserHistory } from 'react-router';
import { sessionService, sessionReducer } from 'redux-react-session';
import { useBeforeunload } from 'react-beforeunload';

import $ from 'jquery';


var moment = require('moment');
var googleAnalytics = require("./common/googleanalytics");
var redirectURL = require('./redirectURL');
const reducer = combineReducers({
    session: sessionReducer
  });

  const store = createStore(reducer);

  // Init the session service
  sessionService.initSessionService(store, { driver: 'COOKIES' });

var rurl='';


class App extends Component {
    constructor(props){
        super(props);
        this.showside = this.showside.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            sidebar:true,
            navMenus:true,
            ssinner:'show-n',
            sideimg:require('../assets/icons/sidenav-blue.png'),
            imgbg:'whgb',
            l20n:'',
            showOutIcon:'show-m',
            sclose:'show-m',
            logginStatus: true,
            userroles:[],
            pageStyle:{},
            inactiveTime: 0,
        }
        this.timer = null;
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
          ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.autologout = this.autologout.bind(this);
        this.userLastActivity = this.userLastActivity.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
        this.checkLoginTime = this.checkLoginTime.bind(this);
		this.cipher = this.cipher.bind(this);
        //this.clearStorage = this.clearStorage.bind(this);

        var userroles = localStorage.getItem("roles");
        this.setState({
            userroles:userroles
        })
        //console.log("userroles", userroles);
        try{
            if (userroles.indexOf("led") == -1)
            {

                for (var i in this.events) {
                    window.addEventListener(this.events[i], this.resetTimeout);
                }
                this.setTimeout();
            }
        }
        catch(e){
            console.error(e);
        }
        //console.log(this.state.sidebar);
    }

	cipher = salt => {
		try{
            const textToChars = text => text.split('').map(c => c.charCodeAt(0));
            const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
            const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');


		}
		catch(e){

		}

	}
    clearTimeout() {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
      }

      setTimeout() {
       // this.setupBeforeUnloadListener();
       var usertype = localStorage.getItem("user_type");

        this.warnTimeout = setTimeout(this.warn, 1 * 1000);
        if(usertype == 'DCTUSER')
        {
            this.logoutTimeout = setTimeout(this.autologout, 60 * 120 * 1000);
        }
        else
        {
            this.logoutTimeout = setTimeout(this.autologout, 60 * 120 * 1000);
        }

      }

      resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
      }

      warn() {
        //alert("You will be logged out automatically in 1 minute.");
      }

      logout() {
          //console.log("loginggggOuttt")
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid'),
            logoutype:"manual"

        }
        redirectURL.post("/manage/removeloggeduser",userloggparams)
        .then((resp) => {
            console.log("resp ", resp)
        })

        // Send a logout request to the API
        //console.log("Sending a logout request to the API...");
        this.setState({ logginStatus: false });
        this.destroy(); // Cleanup
        localStorage.removeItem('token');
    	localStorage.removeItem('userid');
    	localStorage.removeItem('dept_code');
    	localStorage.removeItem('firstname');
    	localStorage.removeItem('lastname');
        localStorage.removeItem('email');
        localStorage.removeItem('loading_port_code');
    	localStorage.removeItem('loadingPointName');
        localStorage.clear();

        sessionStorage.setItem('ref',null);
    	//localStorage.setItem('menuText',[]);
        delete axios.defaults.headers.common['Authorization'];
        this.setState({
            redirectToReferrer:''
        });
        //console.log("logout successfully",localStorage.getItem('token'));

        //localStorage.setItem('token',"");
        //localStorage.setItem('userid',"");

        //window.location.reload();
      }

      autologout() {
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid'),
            logoutype:"auto"

        }
        redirectURL.post("/manage/removeloggeduser",userloggparams)
        .then((resp) => {
            console.log("resp ", resp)
        })
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        // Send a logout request to the API
        //console.log("Sending a logout request to the API...");
        this.setState({ logginStatus: false });
        this.destroy(); // Cleanup
        localStorage.removeItem('token');
    	localStorage.removeItem('userid');
    	localStorage.removeItem('dept_code');
    	localStorage.removeItem('firstname');
    	localStorage.removeItem('lastname');
        localStorage.removeItem('email');
        localStorage.removeItem('loading_port_code');
    	localStorage.removeItem('loadingPointName');
        localStorage.clear();

        sessionStorage.setItem('ref',null);

    	//localStorage.setItem('menuText',[]);
        delete axios.defaults.headers.common['Authorization'];
        this.setState({
            redirectToReferrer:''
        });
        if(is_enmovil_transporter == 1)
        {
            window.location.href="/tptlogin";
        }
        window.location.reload()
        //console.log("logout successfully",localStorage.getItem('token'));

        //localStorage.setItem('token',"");
        //localStorage.setItem('userid',"");

        //window.location.reload();
      }

      checkLoginTime(loggedTime){
        console.log("initial loggedTime", loggedTime);
        var now = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        //var now = moment.parseZone(new Date(nowdate)).utcOffset("+05:30")._d;
        //var loggedTime = moment.parseZone(new Date(loggedTime)).utcOffset("+05:30")._d;
        // var loggedTime = moment.parseZone(new Date(loggedTime)).format('YYYY-MM-DD HH:MM:ss')
        //var loggedTime = moment.parseZone(new Date(loggedTime)).utcOffset("-05:30")._d
        var lTime = moment.parseZone(loggedTime).format("YYYY-MM-DD HH:mm:ss");
        console.log("now", now);
        console.log("loggedTime", loggedTime);
        console.log("loggedTime", lTime);

        if(loggedTime !=undefined)
        {
            //var sessionDuration = moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(loggedTime,"DD/MM/YYYY HH:mm:ss"))).format("mm");
            //moment.parseZone(new Date(data1[0].createdon))._d
            var sessionDurationInHrs = moment.duration(moment(now).diff(lTime));
            var sessionDuration = sessionDurationInHrs.asMinutes();
            console.log("Sess Dur", parseInt(sessionDuration));
            //console.log("Token", localStorage.getItem('token'));
            if(parseInt(sessionDuration) >= parseInt(120))
            {
                console.log("Sess Dur1", sessionDuration);
                this.autologout();
            }
            else
            {
                this.userLastActivity();
            }
        }
        else
        {
            this.userLastActivity();
        }
      }

      userLastActivity()
      {
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid')
        }
        var comp_code = localStorage.getItem('comp_code');
        if(comp_code !=undefined && comp_code !='')
        {
            userloggparams.comp_code = comp_code;
        }
        redirectURL.post("/manage/updateUserLastActivity",userloggparams)
        .then((resp) => {
            console.log("userActivity ", resp)
        })
      }

    // // Setup the `beforeunload` event listener
    // setupBeforeUnloadListener = () => {
    //     window.onbeforeunload((ev) => {
    //         ev.preventDefault();
    //        return this.logout();
    //     });


    // };

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    // setupBeforeUnloadListener = () => {
    //     useBeforeunload(() => "Are you sure to close this tab?");
    //     window.addEventListener("beforeunload", (ev) => {
    //         ev.preventDefault();
    //         console.log("Bhanu");
    //         //this.autologout();
    //     });
    // };

    // useBeforeunload = () => {
    //         console.log("Are you sure to close this tab?");
    //         this.autologout();
    // };


    componentDidMount() {
        // if (localStorage.getItem("email") == "srinu.batchu@enmovil.in") {
            this.resetTimerForInactiveScreen();
            window.addEventListener('mousemove', this.handleMouseMove);
        // }
        //this.clearStorage()
       //
        // Activate the event listener
        //this.setupBeforeUnloadListener();
        //useBeforeunload(() => "Are you sure to close this tab?");
        //window.addEventListener('beforeunload', this.keepOnPage);
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});

        if(localStorage.getItem('token') == '' && localStorage.getItem('token') == null)
        {

        }
        else
        {
            var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var userloggparams = {
                token: localStorage.getItem('token'),
                userId:localStorage.getItem('userid')
            }
            //console.log("userloggparams ", userloggparams)


            redirectURL.post("/manage/loggeduser",userloggparams)
            .then((resp) => {
                //console.log("resp ", resp)
                var recc = resp.data;
                if(recc.length > 0)
                {
                    if(recc[0].is_logged == 0)
                    {
                       this.autologout();
                    }
                    else{

                        this.checkLoginTime(recc[0].last_activity);
                    }
                }
                else
                {
                   // this.logout();
                }
            })
        
            if(localStorage.getItem("system_password_change") == 1 || localStorage.getItem("system_password_change") == "1")
            {
                console.log(window.location.pathname,"window.location.pathname");
                if(window.location.pathname !="/profile/changepassword")
                {
                    window.location.href = "/profile/changepassword";
                }
            }

        }
        //console.log("usermenus ",localStorage.getItem("usermenus"))
        var allmenus = []
        var dynmicemus = []
        if(localStorage.getItem("usermenus") != '' && typeof localStorage.getItem("usermenus") != "undefined")
        {
            var menulist = JSON.parse(localStorage.getItem("usermenus"));
            // console.log("menulist ", menulist)
            try{
                menulist.map((menu) => {
                    //console.log("Menu ", menu)
                    if(menu.sublinks != '' && menu.sublinks != undefined)
                    {

                        menu.sublinks.map((item) => {

                                allmenus.push(item.value)

                        })

                    }
                    if(menu.path == '' || menu.path == null)
                    {
                        var level1 = menu.children;
                        //console.log("level1 ", level1)

                        if(level1.length > 0)
                        {
                            level1.map((mn) => {

                            if(mn.sublinks != '' && mn.sublinks != undefined)
                            {
                                mn.sublinks.map((item) => {

                                        allmenus.push(item.value)

                                })


                            }


                                if(mn.path != '' && mn.path != null)
                                {
                                    allmenus.push(mn.path)
                                }
                                else
                                {
                                    var level2 = mn.children

                                    if(level2.length > 0)
                                    {
                                        level2.map((mnu) => {
                                            if(mnu.path != '' && mnu.path != null)
                                            {
                                                allmenus.push(mnu.path)
                                            }

                                            if(mnu.sublinks != '' && mnu.sublinks != undefined)
                                            {
                                                mnu.sublinks.map((item) => {

                                                        allmenus.push(item.value)

                                                })
                                            }

                                        })
                                    }

                                }

                            })
                        }

                    }
                    else{
                        allmenus.push(menu.path)

                        if(menu.sublinks != '' && menu.sublinks != undefined)
                        {
                            menu.sublinks.map((item) => {

                                    allmenus.push(item.value)

                            })
                        }

                    }
                })
            }
            catch(e){

            }
        }
        //console.log("window.location ", window.location)
        var pageurl = window.location.pathname
        allmenus.push("/")
        allmenus.push("/profile/changepassword")
        allmenus.push("/error-404")
        allmenus.push("/error-405")
        allmenus.push("/logout")
        allmenus.push("/returnTripsDashboard")
        allmenus.push("/get_cyclone_report");
        allmenus.push("/analytics");
        // allmenus.push("/railvinconsignments");
        // allmenus.push("/sndrailvintransshipments");
        // allmenus.push("/prtrailvintransshipments");
        // allmenus.push("/tnprailvintransshipments");
        // allmenus.push("/vinroadpickup");
        // allmenus.push("/sndmanuallocations");
        // allmenus.push("/sndaddlocation");
        // allmenus.push("/editlocation");
        allmenus.push("/sndlocalconsignments");
        allmenus.push("/prtlocalconsignments");
        allmenus.push("/tnplocalconsignments");
        allmenus.push("/raildefault");
        //allmenus.push('/railloadattachment');
        //allmenus.push('/railexitloadattachment');
        //allmenus.push('/actlcontainers');
        //allmenus.push('/userlogreport');
        //allmenus.push('/assignactlcontainers');
        allmenus.push('/livemaptrucklocation');
        allmenus.push('/livemaptruck');
        allmenus.push("/editweatherevent");
        allmenus.push("/newroutedeviation");
        allmenus.push("/batchwisecatalysis");
        allmenus.push("/livetrucksinfo");
        // allmenus.push("/sndbilling");
        // allmenus.push("/s");
        // allmenus.push("/tnpbilling");
        // allmenus.push("/scimportsbilling");
        // allmenus.push("/scexportsbilling");
        // allmenus.push("/sccoilsbilling");
        // allmenus.push("/sndprimarybilling");
        // allmenus.push("/sndsecondarybilling");
        //allmenus.push("/railanalytics");
        //allmenus.push("/updaterailanalytics");
        allmenus.push("/dctattendancelogs");
        allmenus.push("/dctattendancesummary");
        allmenus.push("/dctattendanceview");
        allmenus.push("/deviationroutes");
        allmenus.push("/optimizeroutes");
        allmenus.push("/goldenzonedashboard");
        allmenus.push("/returntrips");
        allmenus.push("/deviationroutesnew");
        allmenus.push("/usdrates");
        allmenus.push("/icdrates");
        allmenus.push("/automotivegeofences");
        allmenus.push("/addautomotivegeofence");
        allmenus.push("/editautomotive");
        allmenus.push("/railinventory");
        allmenus.push("/oembreaks");
        // allmenus.push("/prtdeviationroutes");
        allmenus.push("/markdeliveredrailconsignments");
        // allmenus.push("/sndrouteplanning");
        // allmenus.push("/prtrouteplanning");
        // allmenus.push("/tnpoptimizeroutes");
        // allmenus.push("/tnpdeviationroutes");
        // allmenus.push("/tnprouteplanning");
        // allmenus.push("/prtreturnTripsDashboard");
        allmenus.push("/railplanning");
        allmenus.push("/tnpreturnrtripclusters");
        // allmenus.push("/sndTransitAnalyticsDashbord");
        // allmenus.push("/exportstracking");
        allmenus.push("/analyticsdashboard");
        allmenus.push("/orgrailconsignments");

        allmenus.push("/railloadoptmization");
        allmenus.push("/getOptimizedLoadReport")
        allmenus.push("/sndemptytruckvicinity");
        allmenus.push("/prtcreateconsignment");
        var rurl = [];
        //console.log("allmenus ",allmenus)
        //console.log("path is ",pageurl)
        var otherdepts = ["AD","SA"];
       // console.log("appjsx deptcode ",localStorage.getItem("dept_code"));
        if(allmenus.length > 0)
        {
            try{
                //console.log(localStorage.getItem("dept_code").indexOf(""))
                if(localStorage.getItem("dept_code").indexOf("AD") >= 0 || localStorage.getItem("dept_code").indexOf("SA") >= 0
                )
                {

                }
                else
                {
                   // if(localStorage.getItem("is_load_management") != 1  && localStorage.getItem("roles").includes("air_cargo") != true && localStorage.getItem("roles").includes("air_cargo_form")  != true && localStorage.getItem("roles").includes("ff_air_cargo")  != true && localStorage.getItem("roles").includes("cha_air_cargo")  != true && localStorage.getItem("roles").includes("DCT")  != true)
                    if(localStorage.getItem("is_load_management") != 1 && localStorage.getItem("roles").includes("DCT")  != true)
                     {
                        if(allmenus.indexOf(pageurl) >= 0)
                        {
                            console.log("Valid url")
                        }
                        else{
                           var pgrl =  pageurl.split("/")
                          // console.log("Splitpgrl ",pgrl)
                           if(pgrl.length > 0)
                           {
                               var loadurl=''
                               if(pgrl.length > 1)
                               {
                                 loadurl = "/"+pgrl[1];
                               }
                               if(allmenus.indexOf(loadurl) >= 0 )
                               {
                                //   console.log("yes")
                               }
                               else
                               {
                                 //  console.log("no");
                                 window.location.href="/"
                               }
                           }

                            //window.location.href="/"
                        }
                    }
                    else
                    {
                        // allmenus.push("/lmwelcome");
                        // allmenus.push("/viewLoadSummaryDetails");
                        // if(allmenus.indexOf(pageurl) >= 0)
                        // {
                        //     console.log("Valid url")
                        // }
                        // else{
                        //     window.location.href="/lmwelcome"
                        // }
                    }
                }
            }
             catch(e){

             }
            //console.log(allmenus.indexOf(pageurl))
             var spliturl = pageurl.split("/")

                allmenus.map((a) => {
                    //console.log("a ", a)
                    //console.log("pageurl length ", pageurl.length)
                    if(a.length > 1)
                    {
                        //console.log("pageurl ", pageurl)
                        if(pageurl.indexOf(a) >= 0)
                        {
                            //console.log("Yes")
                            rurl.push(0)
                        }
                        else{
                            //console.log("No")
                            //rurl.push(1)
                            //window.location.href="/error-404"

                        }
                    }


                })

            //}
            //console.log("rurl ", rurl)
            if(rurl.length > 0)
            {
                if(rurl.indexOf(0) >= 0)
                {

                }
                else{
                    // window.location.href="/error-404"
                }
            }
            else{
                if(pageurl == "/")
                {

                }
                else
                {
                    // window.location.href="/error-404"
                }

            }

        }
        if(pageurl == "/loadplandashboard")
        {
            var wstyles={
                "padding": '9px 0 0 0',
            }
            this.setState({
                pageStyle : wstyles
            })
        }
        if(pageurl == "/shareofbusiness")
        {
            var wstyles={
                "padding": '54px 0 0 22px',
            }
            this.setState({
                pageStyle : wstyles
            })
        }
    }
    componentWillReceiveProps(newProps){
        //console.log(newProps)

        //this.clearStorage()

        //console.log("userloggparams ", userloggparams)


        if(localStorage.getItem('token') == '' && localStorage.getItem('token') == null)
        {

        }
        else
        {
            // var userloggparams = {
            //     token: localStorage.getItem('token'),
            //     userId:localStorage.getItem('userid')
            // }
            //console.log("userloggparams ", userloggparams)
            // redirectURL.post("/manage/updateUserLastActivity",userloggparams)
            // .then((resp) => {
            //     console.log("userActivity ", resp)
            // })
        
            if(localStorage.getItem("system_password_change") == 1 || localStorage.getItem("system_password_change") == "1")
            {
                console.log(window.location.pathname,"window.location.pathname");
                if(window.location.pathname !="/profile/changepassword")
                {
                    window.location.href = "/profile/changepassword";
                }
            }
            var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var userloggparams = {
                token: localStorage.getItem('token'),
                userId:localStorage.getItem('userid')
            }

            redirectURL.post("/manage/loggeduser",userloggparams)
            .then((resp) => {
                //console.log("resp ", resp)
                var recc = resp.data;
                if(recc.length > 0)
                {
                    if(recc[0].is_logged == 0)
                    {
                       this.autologout();
                    }
                    else{
                        console.log("Last Activity", recc[0].last_activity);
                        this.checkLoginTime(recc[0].last_activity);
                    }
                }
                else
                {
                   // this.logout();
                }
            })
        }
    }

    componentWillUnmount() {
        if (window.removeEventListener) {
            window.removeEventListener('mousemove', this.handleMouseMove);
            clearTimeout(this.timer);
        }
    }

    resetTimerForInactiveScreen = () => {
        clearTimeout(this.timer);
        const lastActiveTime = localStorage.getItem('lastActiveTime');
        const currentTime = moment();
        const inactiveTime = 120 * 60 * 1000; // 120 minutes in milliseconds
        if (lastActiveTime) {
            const lastActiveMoment = moment(lastActiveTime);
            const timeDiff = currentTime.diff(lastActiveMoment);

            if (timeDiff >= inactiveTime) {
                this.setState({ inactiveTime: inactiveTime });
                this.autologout();
            } else {
                this.timer = setTimeout(() => {
                    this.resetTimerForInactiveScreen();
                }, inactiveTime - timeDiff);
            }
        } else {
            localStorage.setItem('lastActiveTime', currentTime.format()); // Store current time
            this.timer = setTimeout(() => {
                this.resetTimerForInactiveScreen();
            }, inactiveTime);
        }
    };

    handleMouseMove = (event) => {
        if(localStorage.length ==1){
            window.location.href = "/"
        }
        const currentTime = moment();
        localStorage.setItem('lastActiveTime', currentTime.format()); // Update last active time
        this.resetTimerForInactiveScreen();
    };

    // keepOnPage(e) {
    //     //this.autologout();
    //     var message = 'Warning!\n\nNavigating away from this page will delete your text if you haven\'t already saved it.';
    //     e.returnValue = message;
    //     return message;
    // }

    // componentDidUpdate(){
    //     var userloggparams = {
    //         token: localStorage.getItem('token'),
    //         userId:localStorage.getItem('userid')
    //     }
    //     redirectURL.post("/manage/loggeduser",userloggparams)
    //     .then((resp) => {
    //         console.log("resp ", resp)
    //         if(resp.data.length > 0)
    //         {

    //         }
    //         else
    //         {
    //             this.logout();
    //         }
    //     })

    // }

      destroy() {
        this.clearTimeout();

        for (var i in this.events) {
          window.removeEventListener(this.events[i], this.resetTimeout);
        }
      }

    showside(){
        //console.log("asdas");
        showSideBar();
    }
    openCloseSidebar = () => {
        console.log("this.state.sidebar ", this.state.sidebar)
        if(this.state.sidebar){
            this.setState({
                sidebar:true,
                ssinner:'show-m',
                //sideimg:require('../assets/icons/SIDE-NAV.png'),
                imgbg:'',
                l20n:'l20n',
                showOutIcon:'show-m',
                sclose:'show-n'
            })
            document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
            var sidebaricon = document.querySelector(".sidebaricon");
            if(sidebaricon.classList.contains('show-n'))
            {
                document.querySelector(".sidebaricon").classList.remove('show-n');
                document.querySelector(".sidebaricon").classList.add('show-m');
            }
            else{
                document.querySelector(".sidebaricon").classList.remove('show-m');
                document.querySelector(".sidebaricon").classList.add('show-n');
            }
        }
        // else{
        //     this.setState({
        //         sidebar:true,
        //         ssinner:'show-m',
        //         //sideimg:require('../assets/icons/SIDE-NAV.png'),
        //         imgbg:'',
        //         l20n:'l20n',
        //         showOutIcon:'show-n',
        //         sclose:'show-n'
        //     })
        //     document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
        //     this.state.sidebar=false;
        // }
    }

    toggle() {
      //  console.log("test", this.state.navMenus);
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    }
    onShowFullscreen()
    {
        fullscreen()
    }
    // clearStorage() {

    //     let session = sessionStorage.getItem('ref');
    //    // console.log("session ",session)
    //     if (session == null) {
    //         this.autologout()
    //         localStorage.removeItem('token');

    //     }
    //     //sessionStorage.setItem('ref', 0);
    // }

    render(){
        var hideyes=false
       try{
            if(localStorage.getItem("roles").indexOf("led") >= 0)
            {
                hideyes=true
            }
            else{
                hideyes = false
            }
        }
        catch(e){
            hideyes = false
        }

        // Site Under Maintainence code
        // Uncomment site maintainence code
        // console.log("rols", localStorage.getItem("roles"))
        // if(localStorage.getItem("is_load_management") != "1" && localStorage.hasOwnProperty("email") != false
        // && localStorage.getItem("user_type") != "TECHUSER" )
        // {
        //         return <Redirect to="/pages/maintenance" push={true} />
        // }
        // End

        // Original Code
        if(localStorage.getItem('token') == null || localStorage.getItem('token') == '')
        {
            return <Redirect to="/" push={true} />
        }
        // End


        //console.log("Check",localStorage.getItem('token'));
        var pageStyle = this.state.pageStyle;
        return (

            <div className="page-wrapper">

                {
                (hideyes == true)?
                    <div className="page-body-wrapper" style={{overflow:"hidden"}} id="plivebody">
                        <Header />
                        {/* <div  id="fscreen" className="fs-body">
                            <div className="fs-content">
                                <button onClick={this.onShowFullscreen.bind(this)}>FullScreen Mode</button>
                            </div>
                         </div> */}
                         {this.props.children}
                    </div>
                    :
                <div className="page-body-wrapper sidebar-close" id="pageBoby">
                <Header />
                    <Sidebar context= { this } />
                    <div className="page-body" id="pbody" style={this.state.pageStyle}>
                        <a href={"javascript:void(0)"} className={"sidebaricon "+(this.state.showOutIcon)}>
                            {/*<span className="vline"></span>*/}
                            <span className="whbg-bg" id="whbg-bg"></span>
                            <span id="ssinner" className={"siconbg "+ (this.state.ssinner)}></span>
                            {/*
                            <img src={this.state.sideimg} id="sidebar-toggle" defaultChecked={this.state.sidebar} onClick={this.openCloseSidebar} className={"sidemenu "+(this.state.imgbg)+" "+(this.state.l20n)}  alt=""/>
                            */}
                            <img src={require('../assets/icons/sidenav-blue.png')} id="sidebar-toggle" defaultChecked={this.state.sidebar} onClick={this.openCloseSidebar} className={"sidemenu sidebrclose "+(this.state.imgbg)+" "+(this.state.l20n)+" "+(this.state.sclose)}  alt=""/>

                        </a>
                        <ErrorHandler>
                        {this.props.children}

                        </ErrorHandler>
                    </div>
                </div>
                }
            </div>

        );
    }
}



function showSideBar()
{
    alert("test");
    var c=document.getElementById('pageBoby');
    c.removeClass=' sidebar-close';

}

/*
window.onload = function(){
    document.onclick = function(e){
        //console.log(e);
        alert(e.target.className);
        if(e.target.classList.contains('sidemenu'))
        {

            var clickright=document.getElementById('pageBoby');
            if(clickright.classList.contains('sidebar-close'))
            {
                document.getElementById('pageBoby').classList.remove('sidebar-close');
                document.getElementsByClassName('sidemenu')[0].src=require('../assets/icons/SIDE-NAV.png');
                document.getElementById('smnd').classList.remove('whbg');
                document.getElementById('smnd').className += ' bluebg';
                document.getElementById('ssinner').style.display ='block';
                document.getElementById('whbg-bg').style.display ='none';
            }
            else
            {
                document.getElementById('pageBoby').className += ' sidebar-close';
                document.getElementsByClassName('sidemenu')[0].src=require('../assets/icons/sidenav-blue.png');
                document.getElementById('smnd').className += ' whgb';
                document.getElementById('smnd').classList.remove('bluebg');
                document.getElementById('whbg-bg').style.display ='block';
                document.getElementById('ssinner').style.display ='none';
            }
        }
        if(e.target.classList.contains('topmenu'))
        {

            var clickright=document.getElementById('pheader');
            if(clickright.classList.contains('topbar-close'))
            {
                document.getElementById('pheader').classList.remove('topbar-close');
                document.getElementById('tpmenu').classList.remove('movetp');
                document.getElementById('pbody').classList.remove('pbdy');
                document.getElementsByClassName('page-sidebar')[0].style.top='40px';
                document.getElementById('tpmenu').src=require('../assets/icons/TOP-NAV.png');
                document.getElementById('outer').style.display ='block';
                document.getElementById('outer1').style.display ='none';

            }
            else
            {
                document.getElementById('outer').style.display ='none';
                document.getElementById('outer1').style.display ='block';
                document.getElementById('tpmenu').className +=' movetp';
                document.getElementById('pheader').className += ' topbar-close';
                document.getElementById('pbody').className += ' pbdy';
                document.getElementsByClassName('page-sidebar')[0].style.top='0px';
                document.getElementById('tpmenu').src=require('../assets/icons/topnav-blue.png');

            }
        }
        if(e.target.classList.contains('filernow'))
        {
            document.getElementById('filterblock').classList.remove('shwfilt');
        }
        else
        {
           // document.getElementById('filterblock').className +=' shwfilt';
        }
    }
};
*/
window.onload = function(){

}
function fullscreen()
{
    var ele = document.getElementById("plivebody")
   // $("#fscreen").hide();
    console.log("ele ",ele)
    if (ele.requestFullscreen) {
        console.log("S1")
        ele.requestFullscreen();
	} else if (ele.webkitRequestFullscreen) {
        console.log("S2")
		ele.webkitRequestFullscreen();
	} else if (ele.mozRequestFullScreen) {
        console.log("S3")
		ele.mozRequestFullScreen();
	} else if (ele.msRequestFullscreen) {
        console.log("S4")
		ele.msRequestFullscreen();
	} else {
       // $("#fscreen").show();
		console.log('Fullscreen API is not supported.');
	}
}

$(document).ready(function () {

    // var refresh = false;
    // $(window).keydown(function(event) {
    //     if (event.keyCode == 116) { // User presses F5 to refresh
    //        refresh = true;
    //     }
    // });
    // if (refresh == false) {
    //     window.addEventListener('beforeunload', (event) => {
    //         event.returnValue = `Are you sure you want to leave?`;
    //     });
    // }

    // var userroles = localStorage.getItem("roles");
    // //console.log("userroles", userroles);
    // if (userroles.indexOf("led") == -1)
    // {
    //     //console.log("userroles-1", userroles);
    //     window.onbeforeunload = function (e) {
    //         console.log("Here s1")
    //         window.localStorage.unloadTime = JSON.stringify(new Date());

    //     };

    //     window.onload = function () {
    //         console.log("Here s2")
    //         let loadTime = new Date();
    //         let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
    //         let refreshTime = loadTime.getTime() - unloadTime.getTime();
    //         console.log("refreshTime ", refreshTime)
    //         if(refreshTime>2000)//3000 milliseconds
    //         {
    //             console.log("Here s3")
    //             window.localStorage.removeItem("token");
    //         }

    //     };

    // }





    // window.onbeforeunload = function (e) {
    //     window.onunload = function () {
    //         //window.localStorage.isMySessionActive = "false";
    //         console.log("APP JSX",false)
    //     }
    //     return undefined;
    // };

    // window.onload = function () {
    //     //window.localStorage.isMySessionActive = "true";
    //     console.log("APP JSX",true)

    // };


    // function clearStorage() {

    //     let session = sessionStorage.getItem('ref');

    //     if (session == 0) {

    //         localStorage.removeItem('token');

    //     }
    //     sessionStorage.setItem('ref', 1);
    // }
    // window.addEventListener('load', clearStorage);

});


export default App;
